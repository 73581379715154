@use '../../../../styles/vars.scss';

.welcome {
    //padding-top: 64px;
    margin-top: 98px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    //background: rgba(0, 54, 76, 0.534);
    &-p {
        color:white;
        font-size: larger;
        text-decoration: none;
    }
    h1 {
        font-size: 200%;
    }
    &-rotate {
        animation: rotate 0.5s linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(5deg);
        }

        to {
            transform: rotate(0deg);
        }
    }
    &-servers {
        &-img {
            width:96px;
            height:96px;
            border-radius: 50%;
            margin:10px;
        }
        
        &-link {
            #northgard {
                transform: scale(1.1);
            }
            #northgard:hover {
                transform: scale(1.2);
            }
        }
        &-img:hover {
            transform: scale(1.1);
        }
        
    }
    /*
    &-add {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        //background-color: white;
        &-p, &-link {
            color:white;
            font-size: larger;
            text-decoration: none;
        }
        &-link {
            margin-left: 6px;
            margin-right: 6px;
            color:#0097c5
        }
        &-link:hover {
            transform: scale(1.1);
        }
        &-bot {
            visibility:hidden;
            border: solid 2px #00648277;
        }
    }
    */
}
@media (max-width: 640px) {
    .welcome {
        
        margin-top:64px;
        padding: 0px;
        width: 100%;
        font-size: 80%; //
       
    }
} 