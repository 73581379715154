
@use '../../../styles/vars';
.app {
    text-align: center;
    overflow-x: hidden;
}
.login {
    padding-top: 12%;
    max-width: 470px;
    margin: auto;
    &-discord {
        &-link {
            color:white;
            text-decoration: none;
        }
        &-link:hover {
            color:yellow;
            text-decoration: none;
        }
    }
    &-igotit {
        background-color: #00374C;
            color: white;
            border: 50px;
            border-color: #00374C;
            width:80px;
            height: 40px;
            margin-right: auto;
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 45%;
        &-button {
            background-color: #00374C;
            color: white;
            border: 50px;
            border-color: #00374C;
        }
        
    }
}
@media (max-width: 640px) {
    .login {
        padding-top: 64px;
        width: 95%;
        
    }
}