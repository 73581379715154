@use '../../../styles/vars';
.header {
  z-index:7;
  position: fixed;
  top: 0;
  width: 100%; 
  height: 64px;
  margin-right: 0%;
  margin-left:0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00374C;
  &-left {
    width: 20%;
    &-link {
    display:flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    &-logo {
      width: 32px;
      height: 32px;
      border-radius: 45%;
      margin-left: 10px;
      margin-right: 10px;
    }
    &-p {
      color:white;
      font-size: larger;
      text-decoration: none;
    }
  }
  } 
  
  &-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-link {
      display: flex;
      
      align-items: center;
      color:white;
      font-size: larger;
      text-decoration: none;
      margin-right: 10px;
      margin-left:  10px;
    }
  }
  &-right {
    width:20%;
    display: flex;
    justify-content: flex-end;
    
    align-items: center;
    &-link {
      color:white;
      font-size: larger;
      text-decoration: none;
      margin-right: 10px;
      margin-left:  10px;
    }
    &-avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      margin-left:  10px;
    }
  }
}
@media (max-width: 640px) {
  .header {
    //height:20%;
    font-size: smaller;
      &-left {
        &-link {
          &-p {
            visibility: hidden;
          }
        }
      }
      &-right {
        display: flex;
        &-avatar {
          //order: 1;
          visibility: hidden;
        }
      }
  }
}