.dev {
    &-sim {
        padding-top: 20px;
        
        &-compared {
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            font-size: smaller;
            overflow-y: scroll;
            background-color: #006382;
            width: 980px;
            height: 300px;
            margin-right: auto;
            margin-left: auto;
            scrollbar-width: thin;
            &-top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                &-right {
                    margin: 5px;
                    &-date,
                    &-date:link,
                    &-date:visited,
                    &-date:active,
                    &-date:hover {
                        color: white;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
                &-percent {
                    margin: 5px;
                    font-size: 130%;
                    text-shadow: 1px 1px 2px black;
                }
                &-left {
                    margin: 5px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    &-useravatar {
                        padding-right: 6px;
                    }
                }
            }
            &-mid {
                width: 90%;
                margin: auto;
            }
            &-bot {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                &-right {
                    margin: 15px;
                    font-size: 130%;
                    text-shadow: 1px 1px 2px black;
                }
                &-right:hover {
                    transform: scale(1.1);
                    cursor: pointer;
                }
                
                &-left {
                    margin: 20px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    &-no {
                        margin-left:12px;
                        
                    }
                }
            }
        }
        &-list {
            margin-top: 20px;
            height: 400px;
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            font-size: smaller;
            overflow-y: scroll;
            background-color: #00374C;
            width: 980px;
            height: 420px;
            margin-right: auto;
            margin-left: auto;
            scrollbar-width: thin;
            &-f {
                background-color: #006382;
                width:970px;
                margin:5px;
                &-top {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
    
                    &-right {
                        margin: 5px;
                        &-date,
                        &-date:link,
                        &-date:visited,
                        &-date:active,
                        &-date:hover {
                            color: white;
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }
                    &-percent {
                        margin: 5px;
                        font-size: 130%;
                        text-shadow: 1px 1px 2px black;
                    }
                    &-left {
                        margin: 5px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        &-useravatar {
                            padding-right: 6px;
                        }
                    }
                }
                &-mid {
                    width: 90%;
                    margin: auto;
                }
                &-bot {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
    
                    &-right {
                        margin: 15px;
                        font-size: 130%;
                        text-shadow: 1px 1px 2px black;
                    }
                    &-right:hover {
                        transform: scale(1.1);
                        cursor: pointer;
                    }
                    
                    &-left {
                        margin: 20px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        &-no {
                            margin-left:12px;
                            
                        }
                    }
                }
            }
            
        }
    }
}