.mod { 
    padding-top: 64px;
    &-servers, &-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &-servers {
        &-server {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin: 10px;
            cursor: pointer;
        }
        &-server:hover {
            transform: scale(1.1);
        }
    }
    &-buttons {
        &-button {
            background-color: #00374C;
            margin:10px;
            padding:10px;
            cursor:pointer;
        }
        &-button:hover {
            transform: scale(1.1);
        }
    }
    &-logs {
        display:flex;
        flex-wrap: nowrap;
        flex-direction: column;
        font-size: smaller;
        overflow-y: scroll;
        background-color: #006382;
        width:800px;
        height:400px;
        margin-right: auto;
        margin-left: auto;
        scrollbar-width:thin;
        scrollbar-color: #00374C #00364c62
    }
}
@media (max-width: 640px) {
    .mod {
        margin-top:0px;
        padding-top: 64px;
        width: 100%;
        &-buttons, &-servers, &-logs {
            width: 100%;
        }
        &-servers {
            &-server{
                max-width: 48px;
                max-height: 48px;
            }
            justify-content: space-evenly;
        }
        &-buttons {
            justify-content: space-between;
            &-button {
                font-size: 60%;
            }
        }
        &-logs {
            font-size:60%;
        }
    }
}
@media (max-width: 300px) {
    .mod {
        &-buttons, &-servers {
            flex-wrap: wrap;
        }
    }
}