.dashboard { 
    &-private {
        
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        //background-color: rgb(37, 37, 37);
        max-width: 600px;
        margin:auto;
        &-user {
            padding-top: 32px;
            width:64px;
            height:64px;
        }
        &-choose {
            //background-color: rgb(37, 37, 37);
            max-width: 600px;
            font-size: 200%;
        }
        &-addbot {
            color: white;
            text-decoration:none;
            font-size: 150%;
        }
        &-servers {
            //background-color: rgb(12, 12, 12);
            max-width: 600px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin:auto;
            
            &-serv { 
                 
                &-img {
                    width:100px;
                    height:100px;
                    margin: 3%;
                    border-radius: 50%;
                    width: 100%;
                    //background-color: aqua;

                }
                &-img:hover {
                    transform: scale(1.1);
                }
            }
        }
        
    }
}
