// Style the body in the App component
// because semantic ui overtakes the styles in src/styles/index.scss
body {
  //font-family: 'neon' sans-serif;
  background-color: #004D68;//#154D62;//#28292c; //#B5E51C #2A2B2A
  //width: 1920px;
  //height: 1080px;
  margin:0%;
  color:white;
}
.app {
  width:100%;
}

 