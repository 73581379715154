@use '../../../styles/vars';
.footer {
  z-index:7;
  bottom: 0;
  position: fixed;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00374C;
  &-link {
    align-items: center;
    color:white;
    font-size: larger;
    text-decoration: none;
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media (max-width: 640px) {
  .footer {
    font-size: small;
  }
}