@use '../../../styles/vars';

.guide {
    
    display: flex;
    flex-direction: column; 
    flex-wrap:nowrap;
    justify-content: space-between;
    //max-width:400px;
    margin:auto;
    margin-top:64px;
    margin-bottom: 64px;
    width:100%;
    &-img {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    
    a {
        color:rgb(79, 191, 243);
        text-decoration: none;
    }
    &-boxes {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width:90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        &-box {
            width:33%;
        }
    }
}
@media (max-width: 640px) {
    .guide {
        &-imgsource {margin-left: auto;margin-right: auto;justify-content: center;}
      &-img {max-width: 95%;width:90%;margin-left: auto;margin-right: auto;}
     
      &-boxes {
        flex-direction: column;
        width:100%;
        &-box {
            width:90%;
            font-size: small;
            margin-left: auto;
            margin-right: auto;
        }
      }
      a {
        height:20px;
        height:120%;
        padding:10px;
      }
    }
  }