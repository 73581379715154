@use '../../../styles/vars';

.features {
    
    display: flex;
    flex-direction: column; 
    flex-wrap:nowrap;
    justify-content: space-between;
    max-width:300px;
    margin:auto;
    padding-top: 65px;
    margin-top:200px;
}