@use '../../../styles/vars';

.contact {
    
    display: flex;
    flex-direction: column; 
    flex-wrap:nowrap;
    justify-content: space-between;
    max-width:800px;
    margin:auto;
    padding-top: 65px;
    margin-top:200px;
    &-link {
        color:rgb(0, 162, 211);
        font-size: larger;
        text-decoration: none;
    }
}