@use '../../../styles/vars';

.terms {
    
    display: flex;
    flex-direction: column; 
    flex-wrap:nowrap;
    justify-content: space-between;
    max-width:900px;
    margin:auto;
    margin-top: 64px;
    margin-bottom: 64px;
}
@media (max-width: 640px) {
    .terms {
      //font-size: smaller;
      font-size: 75%;
    }
  }