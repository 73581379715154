
@use '../../../styles/vars';
.app {
    text-align: center;
    overflow-x: hidden;
}
.logout {
    padding-top: 64px;
    max-width: 600px;
    margin: auto;
    &-p {
        font-size: 65%;
    }
}
@media (max-width: 640px) {
    .logout {
        padding-top: 32px;
        width: 95%;
        &-img {
            width: 100%;
        }
        
    }
}