.dev {
    padding-top: 10px;
    
    &-servers,
    &-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &-servers {
        &-server {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin: 10px;
            cursor: pointer;
            
        }

        &-server:hover {
            transform: scale(1.1);
            
        }
    }

    &-buttons {
        
        &-button {
            background-color: #00374C;
            margin: 10px;
            padding: 10px;
            cursor: pointer;
        }

        &-button:hover {
            transform: scale(1.2);
        }
    }

    &-feedback {
        
        width: 100%;
        height: 100vh;
        margin: auto;
        padding-bottom: 128px;

        &-tags {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: auto;
            flex-wrap: wrap;
            &-box {
                display: flex;
                flex-direction: row;
                
            }

            &-t {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-shadow: 1px 1px 2px black;
                
                &-input {
                    margin: 3px;
                    appearance: none;
                    padding: 8px 15px;
                    border-radius: 8px;
                    background: radial-gradient(circle 6px, white 100%, transparent calc(100% + 1px)) #00374C -7px;
                    transition: 0.3s ease-in-out;

                }

                &-input:checked {
                    background-color: dodgerBlue;
                    background-position: 7px;
                }
                
            }
        }

        &-list {
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            font-size: smaller;
            overflow-y: scroll;
            background-color: #006382;
            width: 90%;
            height: 600px;
            margin-right: auto;
            margin-left: auto;
            scrollbar-width: thin;

            &-f {
                margin-bottom: 20px;
                border-bottom: 10px;
                border-color: #00374C;

                &-top {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    &-right {
                        margin: 5px;
                        &-date,
                        &-date:link,
                        &-date:visited,
                        &-date:active,
                        &-date:hover {
                            color: white;
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }

                    &-left {
                        margin: 5px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        &-useravatar {
                            padding-right: 6px;
                        }
                    }
                }
                &-mid {
                    width: 90%;
                    margin: auto;
                }
                &-bot {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    &-right {
                        margin: 15px;
                        font-size: 200%;
                    }
                    &-right:hover {
                        transform: scale(1.5);
                        cursor: pointer;
                    }
                    
                    &-left {
                        margin: 20px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        &-no {
                            margin-left:12px;
                            
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .dev {
        margin-top: 0px;
        padding-top: 64px;
        width: 100%;

        &-buttons,
        &-servers,
        &-logs {
            width: 100%;
        }

        &-servers {
            &-server {
                max-width: 48px;
                max-height: 48px;
            }

            justify-content: space-evenly;
        }

        &-buttons {
            justify-content: space-between;

            &-button {
                font-size: 60%;
            }
        }

        &-feedback-list {
            overflow-y: scroll;
            font-size: 60%;
        }
    }
}

@media (max-width: 300px) {
    .dev {

        &-buttons,
        &-servers {
            flex-wrap: wrap;
        }
    }
}